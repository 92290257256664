import makeCall, { baseUrl } from '../../services/makeCall'
import resourceid from '../../constants/resourceid'
const mediaUrl = baseUrl + '/open/media/a1Api/images/view/'

export const state = {
    servicesOffered: {
        loaded: false,
        data: {}
    },
    gallery: {
        loaded: false,
        data: []
    },
    featuredImages: {
        loaded: false,
        data: []
    }
}

export default {
    state,
    mutations: {
        updateServicesOffered (state, payload) {
            state.servicesOffered = {
                loaded: true,
                data: payload
            }
        },
        updatedGalleryData (state, payload) {
            state.gallery = {
                loaded: true,
                data: payload
            }
        },
        updateFeaturedImages (state, payload) {
            state.featuredImages = {
                loaded: true,
                data: payload
            }
        }
    },
    actions: {
        async loadedServicesOffered ({commit}) {
            const resp = await makeCall({ url: `services/${resourceid}` })
            commit('updateServicesOffered', resp)
        },
        async loadGalleryData ({commit}) {
            const resp = await makeCall({ url: `gallery_images/${resourceid}` })
            commit(
                'updatedGalleryData',
                [...resp].map(i => {
                    const url = mediaUrl + i.url
                    return {
                        ...i,
                        image: url,
                        url: url,
                        thumb: mediaUrl + i.thumb
                    }
                })
            )
        },
        async loadFeaturedImages ({commit}) {
            const resp = await makeCall({ url: `featured_images/${resourceid}` })
            commit('updateFeaturedImages', [...resp].map(i => {
                const url = mediaUrl + i.url
                return {
                    ...i,
                    image: url,
                    url: url,
                    thumb: mediaUrl + i.thumb
                }
            }))
        }
    },
    getters: {
        servicesOffered (state) {
            return state.servicesOffered
        },
        gallery (state) {
            return state.gallery
        },
        featuredImages (state) {
            return state.featuredImages
        },
        splash (state) {
            return state.splash
        }
    }
}

