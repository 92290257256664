<template>
  <span class='link-list'>
      <span v-for="(link, index) in links" :key="index">
        <a
          class='side-bar-link'
          v-if="link.action"
          @click.prevent="() => handleClick(link)"
        >
          <span class='icon' v-if="hideIcons !== true">
            <font-awesome-icon :icon="link.icon" />
          </span>
          {{ link.text }}
        </a>
        <router-link
          v-else
          :to="link.to"
          class='side-bar-link'
        >
          <span class='icon' v-if="hideIcons !== true">
            <font-awesome-icon :icon="link.icon" />
          </span>
          {{ link.text }}
        </router-link>
      </span>
    </span>
</template>

<script>
export default {
  props: ['links', 'hideIcons'],
  watch: {
    $route (to, from) {
      window.scrollTo({ top: 0, left: 0 })
    }
  },
  methods: {
    handleClick (link) {
      if (link.action && (link.actionVal || link.actionVal === false)) {
        this.$store.commit(link.action, link.actionVal)
      }
      if (link.require && this.$route.path !== link.require) {
        this.$router.push(link.require)
        setTimeout(() => this.handleItemResponse(link), 200)
      } else this.handleItemResponse(link)
    },
    handleItemResponse (link) {
      if(link.to && link.to === '/home'){
        window.scrollTo({ top: 0, left: 0 })
      } else {
        const item = document.getElementsByClassName('section-' + link.to.replace(/ /g, ''))
        if (item && item.length > 0) {
          window.scrollTo({ top: 0, left: 0 })
          setTimeout(() => {
            const rect = item[0].getBoundingClientRect()
            window.scrollTo({
              top: rect.top,
              left: 0,
              behavior: 'smooth'
            })
          }, 200)}
      }

    }
  }
}
</script>
