<template>
  <div>
    <div class='page-header-accent'>
      <div>
        <h3 class='head'>Some of our work</h3>
        <p class='page-descr'>
          View some of our work here.
        </p>
      </div>
    </div>
    <div class='gallery-container'>
      <p
        v-if="!gallery.loaded"
        class='t-c p-10 strong pt-30'
      >
        Loading images...
      </p>
      <p
        v-if="images.length === 0 && gallery.loaded"
        class='t-c p-10 strong pt-30'
      >
        There are no images to display.
      </p>
      <image-gallery 
        v-if="images.length > 0" 
        :images="images"
        :config="{ margin: 10, radius: 8 }"
        :handleClick="handleImageClick"
        :mobileView="$store.getters.isMobile"
      />
    </div>
  </div>
</template>

<script>
import ImageGallery from '../../components/myComponents/Gallery.vue'
export default {
  components: { ImageGallery },
  name: 'gallery',
  mounted: function () {
    if (!this.gallery.loaded) {
      this.$store.dispatch('loadGalleryData')
    }
  },
  methods: {
    handleImageClick (image) {
      this.$store.commit('updateModal', {
        image,
        display: true,
      })
    }
  },
  computed: {
    images () {
      return this.gallery.data
    },
    gallery () {
      return this.$store.getters.gallery
    }
  }
}
</script>

<style scoped lang='scss'>
.gallery-container{
  padding-top: 50px;
}
</style>
