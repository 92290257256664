<template>
  <div class='about-container'>
    <div class='page-header-accent'>
      <div>
        <h3 class='head'>Who we are</h3>
        <p class='page-descr'>
          A1 signs is a family owned, local business that has been proudly serving customers out of the Houston, Texas area for over 15 years.
        </p>
      </div>
    </div>
    <div class='container'>
      <h4>Our location</h4>
      <div class='map-container'>
        <p>
          <span class='strong'>We are located at the conventient Humble location:</span> <br />
          2025 FM 1960 Bypass Road E. Humble Texas 77338. (Next to Shrader's Automotive)
        </p>
        <iframe 
          class="lined" 
          :width="mapContainerSize" 
          height="350" 
          frameborder="0" 
          scrolling="no" 
          marginheight="0" 
          marginwidth="0" 
          src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;q=2025+Fm+1960+Bypass+Rd+E,+Humble,+Harris,+Texas+77338&amp;aq=&amp;sll=37.0625,-95.677068&amp;sspn=48.909425,107.138672&amp;vpsrc=6&amp;ie=UTF8&amp;geocode=FajFyQEdYrBS-g&amp;split=0&amp;hq=&amp;hnear=2025+Fm+1960+Bypass+Rd+E,+Humble,+Harris,+Texas+77338&amp;ll=30.000552,-95.24419&amp;spn=0.006578,0.013078&amp;z=14&amp;output=embed"
          >
        </iframe>
      </div>
      <h4>Our history</h4>
      <p class='par'>
        A1 Signs and Graphics started out years ago as a small local business with a small client base. Now we're able to offer our services to clients all over the United States. Our client base has grown along with our company, and the services we offer have expanded greatly.
      </p>
      <p class='par'>
        All of our designs are produced in house, making sure every design is made exclusively to fit your needs. Unless requested otherwise, we save a copy of all your work to make re-orders faster, accurate, and more convenient for you. 
      </p>
      <p class='par'>
        We even offer the customer a copy of the original digital file for future projects, for example reprints, t-shirts, and much more.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  name: 'About',
  data: () => ({ mapContainerSize: 675 }),
  watch: {
    windowWidth () {
      this.checkWindowWidth()
    }
  },
  mounted: function (){ this.checkWindowWidth() },
  methods: {
    checkWindowWidth () {
      const sideBarWidth = this.$store.getters.windowWidth < 700 ? 0 : 280
      let mapWidth = 675
      let newWidth = this.$store.getters.windowWidth - 30 - sideBarWidth
      if (newWidth < mapWidth) this.mapContainerSize = newWidth
      else this.mapContainerSize = mapWidth
    }
  },
  computed: {
    windowWidth () {
      return this.$store.getters.windowWidth
    },
  }
}
</script>

<style scoped lang='scss'>
@import '@/styles/vars.scss';
.about-container {
  margin: 0 auto;
  .map-container {
    max-width: 675px;
    & > p {
      background-color: $accent-1;
      color: #fff;
      padding: 5px 10px;
      border-radius: 3px;
      margin: 0;
    }
  }
  h4 {
    font-size: 1.4rem;
    margin-top: 50px;
    margin-bottom: 20px;
    color: $accent-1;
  }
}
</style>
