import makeCall from "../../services/makeCall";

const mobileLimit = 700;

export const state = {
  windowHeight: 1000,
  windowWidth: 1000,
  scrollPos: 0,
  sideOpen: true,
  quoteForm: false,
  contactKey: null,
  contact: {
    phone: "346-612-9031",
    email: "shopa1wraps@gmail.com"
  },
  modal: {
    display: false,
    image: null
  }
};

export default {
  state,
  mutations: {
    updateModal(state, payload) {
      state.modal = payload;
    },
    updateQuoteForm(state, payload) {
      state.quoteForm = payload;
    },
    updateWindowWidth(state, payload) {
      if (state.windowWidth > mobileLimit && payload <= mobileLimit) {
        state.sideOpen = false;
      } else if (!state.sideOpen && payload > mobileLimit) {
        state.sideOpen = true;
      }
      if (state.windowHeight !== window.innerHeight) {
        state.windowHeight = window.innerHeight;
      }
      state.windowWidth = payload;
    },
    updateWindowHeight(state, payload) {
      state.windowHeight = payload;
    },
    updateScrollPos(state, payload) {
      if (state.windowHeight !== window.innerHeight) {
        state.windowHeight = window.innerHeight;
      }
      state.scrollPos = payload;
    },
    updateSideOpen(state) {
      state.sideOpen = !state.sideOpen;
    },
    updateContactKey(state, payload) {
      state.contactKey = payload;
    }
  },
  getters: {
    windowWidth(state) {
      return state.windowWidth;
    },
    windowHeight(state) {
      return state.windowHeight;
    },
    scrollPos(state) {
      return state.scrollPos;
    },
    sideOpen(state) {
      return state.sideOpen || !(state.windowWidth < mobileLimit);
    },
    isScrolled(state) {
      return (
        state.scrollPos > state.windowHeight ||
        (state.windowWidth < mobileLimit && state.sideOpen)
      );
    },
    isMobile(state) {
      return state.windowWidth < mobileLimit;
    },
    quoteForm(state) {
      return state.quoteForm;
    },
    modal(state) {
      return state.modal;
    },
    contact(state) {
      return state.contact;
    }
  },
  actions: {
    async submitForm({ state, commit }, payload) {
      const callData = { endPoint: "open/email", url: "a1signs" };

      if (!state.contactKey) {
        const keyResp = await makeCall(callData);
        commit("updateContactKey", keyResp.key);
      }

      const compiledMessage = Object.keys(payload).reduce((p, c) => {
        if (c === "formType") return p;

        const dataItem =
          c === "email"
            ? `<a href='mailto:${payload[c]}' target="_blank">${payload[c]}</a>`
            : payload[c];

        return `${p}<b>${c}:</b> ${dataItem} <br />`;
      }, `<b>You've received a new message from your contact form</b> <br /><br />`);

      const postResp = await makeCall({
        ...callData,
        method: "POST",
        body: {
          key: state.contactKey,
          subject: `New message from A1 Signs ${payload.formType} form`,
          message: compiledMessage,
          from: "contactform@a1signs.biz",
          to: state.email
        }
      });
      return { success: postResp && postResp.error === false };
    }
  }
};
