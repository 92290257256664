<template>
  <div class='header-bar'>
    <span class='head'>
      <side-bar-toggle :size="'30px'" :marginRight="6" />
      A1 Signs
    </span>
    <span>
      <span class='phone-icon'>
        <span>
          <font-awesome-icon icon="phone" />
        </span>
      </span>
      {{ $store.getters.contact.phone }}
    </span>
  </div>
</template>

<script>
import SideBarToggle from './SideBarToggle.vue'
export default {
  components: {
    SideBarToggle
  }
}
</script>

<style lang='scss'>
.header-bar {
  position: fixed;
  top: -20px;
  left: 0;
  height: 0;
  width: 100%;
  z-index: 13;
  transition: all .3s;
  opacity: 0;
  background-color:rgba(0, 0, 0, 0.7);
  justify-content: space-between;
  align-items: center;
  text-shadow: 1px 1px 1px black;
  & > span {
    padding: 14px 10px;
    color: #fff;
    font-weight: bold;
    &.head {
      font-size: 1.2rem;
    }
    .phone-icon {
      border: 3px solid #fff;
      background-color: #fff;
      border-radius: 50%; 
      color: #333;
      height: 19px;
      width: 19px;
      margin-right: 8px;
      display: inline-block;
      span {
        padding-top: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.isScrolled .header-bar {
  display: flex;
  opacity: 1;
  top: 0;
  height: auto;
}
</style>
