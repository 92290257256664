<template>
    <div class='my-component-tabbed-content'>
        <ul class='tabs'>
            <li 
                v-for="(t, i) in Object.keys(content)"
                :key='i'
                @click="() => updateSelectedTab(t)"
                :class="{selected: t === selectedTab}"
            >
                {{ t }}
            </li>
        </ul>
        <div class='tab-content'>
            <div :class="tabClass">
                <h4>{{selectedTab}}</h4>
                {{ tabContent }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['content'],
    data: () => ({
        selectedTab: '',
        tabClass: 'un-mounted'
    }),
    computed: {
        tabContent () {
            if (this.selectedTab === '') return this.content[Object.keys(this.content)[0]]
            else return this.content[this.selectedTab]
        }
    },
    mounted: function (){
        if (this.selectedTab === ''){
            this.updateSelectedTab(Object.keys(this.content)[0])
        }
    },
    methods: {
        updateSelectedTab (tab) {
            this.tabClass = 'un-mounted'
            setTimeout(() => {
                this.selectedTab = tab
                this.tabClass = 'mounted'
            }, 400);
        }
    }
}
</script>

<style lang="scss" scoped>
.my-component-tabbed-content {
    display: flex;
    box-shadow: 0 0 3px rgba(32, 32, 32, 0.438);
    .tabs {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: rgb(210, 221, 231);
        width: 25%;
        & > li {
            border-left: 5px solid rgb(192, 192, 192);
            padding: 20px 10px;
            cursor: pointer;
            color: #000;
            transition: all .3s;
            &:hover {
                background-color: rgb(0, 27, 116);
                padding-left: 18px;
                color: #fff;
            }
            &.selected {
                border-left: 5px solid rgb(0, 27, 116);
            }
        }
    }
    .tab-content {
        width: 75%;
        background-color: rgb(241, 241, 241);
        & > div {
            & h4 {
                font-weight: bold;
                font-size: 1.4rem;
                margin-bottom: 16px;
                margin-top: 13px;
                color: rgb(37, 37, 37);
                width: 90%;
                border-bottom: 3px solid rgb(54, 54, 54);
                padding-bottom: 15px;
            }
            width: 96%;
            margin: 0 auto;
            margin-top: 2%;
            line-height: 1.85rem;
            color: #444;
            transition: opacity .1s;
            &.un-mounted{
                opacity: 0;
            }
            &.mounted {
                opacity: 1;
            }
        }
    }
}
</style>