<template>
  <div
    id="app"
    :class="{
      isScrolled: isScrolled,
      isMobile: $store.getters.isMobile
    }"
  >
    <header-bar />
    <header-component v-if="page==='Home'" />
    <div 
      class="app-content-container"
      :class="{
        sideOpen: sideOpen,
        isHome: page === 'Home'
      }"
    >
      <side-bar :links="links" :sideOpen="sideOpen" :isMobile="$store.getters.isMobile" />
      <div class="main-content">
        <router-view />
        <footer-content />
      </div>
    </div>
    <modal :isMounted="modal.display" :image="modal.image" :onCloseClick="onModalCloseClick" />
  </div>
</template>

<script>
import SideBar from './components/SideBar.vue'
import HeaderComponent from './components/Header.vue'
import HeaderBar from './components/HeaderBar.vue'
import FooterContent from './components/Footer.vue'
import Modal from './components/myComponents/Modal.vue'
import releaseVersion from './releaseVersion'
import links from '@/router/links.js'
export default {
  components: { SideBar, HeaderComponent, HeaderBar, Modal, FooterContent },
  data: () => ({
    links, releaseVersion 
  }),
  mounted: function () {
    window.addEventListener('scroll', this.checkScrollPos)
    window.addEventListener('resize', this.checkWindowWidth)
    this.checkScrollPos()
    this.checkWindowWidth()
  },
  methods: {
    checkScrollPos () {
      const pos = window.scrollY
      if (pos !== this.$store.getters.scrollPos) {
        this.$store.commit('updateScrollPos', pos)
      }
    },
    checkWindowWidth () {
      const width = window.innerWidth
      const height = window.innerHeight
      if (width !== this.$store.getters.windowWidth) {
        this.$store.commit('updateWindowWidth', width)
      }
      if (height !== this.$store.getters.windowHeight){
        this.$store.commit('updateWindowHeight', height)
      }
    },
    onModalCloseClick () {
      this.$store.commit('updateModal', {
        image: {},
        display: false,
      }) 
    }
  },
  computed: {
    modal () { 
      return this.$store.getters.modal
    },
    sideOpen () {
      return this.$store.getters.sideOpen
    },
    page () {
      return this.$route.name
    },
    isScrolled () {
      return this.page !== 'Home' || (this.page === 'Home' && this.$store.getters.isScrolled)
    }
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.checkScrollPos)
    window.removeEventListener('scroll', this.checkWindowWidth)
  }
}
</script>

<style lang="scss">
@import '@/styles/vars.scss';
#app {
  position: relative;
}
.page-title {
  font-weight: bold;
  font-size: 1.5rem;
  color: green;
}
.app-content-container {
  margin-top: 0;
  &.isHome {
    margin-top: $header-height;
  }
  position: relative;
  display: flex;
  transition: all 1s;
  z-index: 2;
  background-color: rgb(235, 235, 235);
  .main-content {
    transition: margin $side-bar-speed;
    width: 100%;
  }
}
.page-header-accent {
  background-color: $accent-2;
  margin-bottom: 20px;
  color: #fff;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 10px;
  div {
    width: 98%;
    margin: 0 auto;
    .head {
      font-size: 1.8rem;
      width: 98%;
      margin: 40px auto 20px auto;
    }
    .page-descr {
      width: 98%;
      line-height: 2rem;
      margin: 0 auto 20px auto;
    }
  }
}
.container {
  width: 98%;
  margin: 0 auto;
}
</style>
