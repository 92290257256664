import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './routes/Home'

const Gallery = () => import(/* webpackChunkName: "gallery" */ './routes/Gallery.vue')
const Services = () => import(/* webpackChunkName: "services" */ './routes/Services.vue')
const About = () => import(/* webpackChunkName: "about" */ './routes/About.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  }
]

const router = new VueRouter({
  routes
})

export default router
