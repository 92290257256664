<template>
  <div>
    <span v-if="images.length > 0">
      <div class='bg-accent' />
      <h3 class='head'>Some of our work</h3>
      <div class='gallery-container section-home'>
        <gallery :images="images" :config="{ margin: 8, radius: 3 }" :hideCover="true" />
      </div>
      <div class='see-more'>
        <span>
          <font-awesome-icon icon='hand-point-right' />
          <router-link to='gallery'>
            Like what you see? Click here to view more on our gallery page.
          </router-link>
        </span>
      </div>
    </span>
    <contact />
  </div>
</template>

<script>
import Gallery from '../../components/myComponents/Gallery'
import Contact from '../../components/Contact'

export default {
  components: { Gallery, Contact },
  mounted: function () {
    if (!this.featuredImages.loaded) {
      this.$store.dispatch('loadFeaturedImages')
    }
  },
  computed: {
    featuredImages () {
      return this.$store.getters.featuredImages
    },
    images () {
      return this.featuredImages.data
    }
  },
  name: 'Home-route'
}
</script>

<style lang='scss' scoped>
@import '@/styles/vars.scss';
.see-more {
  background-color: rgb(65, 65, 65);
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 25px;
  span {
    color: #d0d0d0;
    padding: 6px 10px;
    border-radius: 15px;
    margin: 0;
  }
  a {
    font-weight: bold;
    color: inherit;
    font-size: 1.1rem;
    text-decoration: none;
  }
  svg {
    font-size: 1.6rem;
    margin-right: 10px;
    margin-bottom: -4px;
  }
}
.head {
    font-size: 2rem;
    width: 96%;
    text-align: right;
    color: rgb(255, 255, 255);
    margin: -150px auto 10px auto;
  }
.bg-accent{
  background-color: $accent-1;
  height: 200px;
  width: 100%;
}
.gallery-container{
  width: 98%;
  margin: 0 auto 20px auto;
}
</style>
