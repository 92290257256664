<template>
  <div class='section-contact'>
    <div class='bg-accent' />
    <div class='contact-content split c-v pb-10'>
      <div>
        <h3 class='head'>{{ this.pageHeader }}!</h3>
      </div>
      <div class='form-toggle-container t-r'>
        <p class='m-0'>
          {{ quoteForm ? 'Wanting to get in touch?' : 'Looking for a qoute?' }}
        </p>
        <button class='btn' @click='updateFormType'>
          {{ quoteForm ? 'Contact form' : 'Quote request' }}
        </button>
      </div>
    </div>
    <div class='split contact-body'>
      <div class='form-container col-60 pt-10'>
        <p class='w-96 mt-30 m-c t-c par'>
          {{ formMessage }}
        </p>
        <my-form 
          :fields="formFields" 
          :retainInputsOnUpdate="true"
          :onSubmit="formHandler"
          :hasSecurity="true"
          :forceLabel="true"
        />
      </div>
      <div class='col-35 hours'>
        <h4 class='head'>Contact info</h4>
        <p>
          <span>Email address:</span>
          <a
            :href="'mailto:' + $store.getters.contact.email"
            class='body-link'
            target="_blank"
          >{{$store.getters.contact.email}}
          </a>
        </p>
        <p>
          <span>Phone number:</span> {{ $store.getters.contact.phone }}
        </p>
        <p>
          <span>Phone number:</span> 346-612-5997
        </p>
        <br />
        <h4 class='head'>Business Hours</h4>
        <soft-list
          :listItems="[
            ['Monday - Friday', '9:00am - 6:00 pm'],
            ['Saturday', 'By appointment only'],
            ['Sunday', 'Office is closed']
          ]"
          :accentColor="'white'"
        />
        <p class='t-c'>
          Business hours do not reflect office hours for holidays or unforeseen
          events.
        </p>
        <div class='map-link-container'>
          <link-list :links="Links.filter(l => l.to === 'map-container')" class='map-link' />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftList from './myComponents/SoftList';
import myForm from './myComponents/Form';
import Links from '../router/links'
import LinkList from './LinkList.vue';
export default {
  name: 'contact',
  components: { SoftList, myForm, LinkList },
  data: () => ({ Links }),
  methods: {
    updateFormType () {
      this.$store.commit('updateQuoteForm', !this.quoteForm)
    },
     async formHandler (data) {
      const response = await this.$store.dispatch('submitForm', {
        ...data,
        formType: this.quoteForm ? 'quote': 'contact'
      })
      if (response.success) {
        return ({
          message: 'Form successfully submitted',
          clear: true
        })
      }
      return ({ 
        error: true,
        message: 'Error submitting form. Please try again'
      })
    }
  },
  computed: {
    formMessage () {
      return this.quoteForm ?
          `Complete the following information to request a quote. 
          The more information you provide, 
          the more accurate quote we can give you. 
          We will respond to your request as soon as possible.`
        : `To contact us, you can use our contact form, give us a call, email, or
          stop by our office during normal business hours. We will get back to
          you as soon as possible.`
    },
    quoteForm () {
      return this.$store.getters.quoteForm
    },
    pageHeader () {
      return this.quoteForm ? 'Request a quote' : 'Get in touch';
    },
    formFields () {
      let fields = [
        { name: 'name', size: 'long' },
        { name: 'email', label: 'Email address', validate: { type: 'email' } },
        { name: 'number', label: 'Phone number', validate: { type: 'phone' } },
        { name: 'message', size: 'long', type: 'textArea', validate: { required: true } }
      ]
      if (this.quoteForm) {
        fields = [...fields].map(f => ({ ...f, group: 'Contact info' })).splice(0, fields.length - 1)
        fields.push(
          { name: 'Type', label: 'Job type', group: 'Quote info', type: 'text', size: 'long' },
          { name: 'Quantity', group: 'Quote info', type: 'number' },
          { name: 'Size', group: 'Quote info' },
          { name: 'message', label: 'Job description', size: 'long', type: 'textArea', group: 'Additional info' }
        )
      }
      return fields
    }
  }
}
</script>

<style lang='scss'>
@import '@/styles/vars.scss';
.section-contact {
  .form-container {
    background-color: #fff;
    border-top: 10px solid rgb(243, 66, 66);
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  }
  .bg-accent{
    background-color: $accent-2;
    height: 200px;
    width: 100%;
  }
  .contact-content{
    margin-top: -150px;
    .head{
      font-size: 3rem;
      color: #fff;
      margin: 0 0 10px 0;
    }
    .form-toggle-container {
      padding-right: 20px;
      text-align: center;
      p {
        color: #fff;
        font-weight: bold;
        padding-bottom: 12px;
      }
      button {
        border: 3px solid #fff;
        color: #fff;
        padding: 10px 23px;
        background-color: rgb(243, 66, 66);
      }
    }
  }
  .hours{
    margin-top: 80px;
    p {
      margin-bottom: 25px;
      span {
        margin-right: 10px;
        font-weight: bold;
      }
      a {
        text-decoration: none;
      }
    }
    .head {
      font-size: 1.5rem;
      margin: 10px 0 20px 0;
    }
  }
  .map-link-container {
    margin: 0 auto;
    padding: 0;
    .link-list {
      a {
        color: #fff;
        font-weight: bold;
        border-radius: 6px;
        font-size: 1.3rem;
        display: block;
        height: auto;
        max-width: 250px;
        margin: 0 auto;
        padding: 10px;
        padding-left: 15px;
        background-color: green;
        display: block;
        cursor: pointer;
        margin-top: 45px;
        span {
          padding-right: 17px;
        }
        &:hover{ 
          background-color: gray;
        }
      }
    }
  }
  @media screen and (max-width: $med-break){
    & > .contact-body {
      flex-direction: column;
      padding: 0;
      & > div {
        width: 100%;
        margin: 15px 0;
      }
    }
  }
}

</style>
