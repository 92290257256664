<template>
  <span class='side-bar-back' @click.stop="handleShadowClick">
    <div class="side-bar">
      <link-list :links="links" />
      <a class='send-email' :href="toLink" target="_blank">
        <span><font-awesome-icon icon="envelope" /></span>
        <span>send us an email</span>
      </a>
    </div>
  </span>
</template>

<script>
import LinkList from './LinkList.vue'
export default {
  components: { LinkList },
  props: ['links', 'sideOpen', 'isMobile'],
  computed: {
    toLink () {
      return 'mailto:' + this.$store.getters.contact.email
    }
  },
  methods: {
    handleShadowClick (e) {
      const { classList } = e.target
      if(classList && this.sideOpen &&
          this.isMobile &&
              (classList.contains('side-bar-link') || classList.contains('side-bar-back'))
        ){
        this.$store.commit('updateSideOpen')
      }
    }
  },
  name: 'SideBar'
}
</script>

<style lang="scss">
@import '@/styles/vars.scss';
.side-bar {
  width: $side-bar-closed-width;
  transition: margin 1s, height 1s, width $side-bar-speed;
  height: 100vh;
  z-index: 1;
  top: 0;
  overflow: auto;
  left: 0;
  position: sticky;
  background-color: $side-bar-color;
  justify-content: space-between;
  flex-direction: column;
  .link-list {
    width: $side-bar-open-width;
    display: block;
    margin: 20px auto 0 auto;
    transition: padding .4s;
    .side-bar-link {
      display: block;
      background-color: $side-bar-color;
      padding: 23px 0 20px 10px;
      transition: background-color .5s, color 1s;
      text-decoration: none;
      color: rgb(105, 105, 105);
      font-weight: bold;
      cursor: pointer;
      & .icon {
        margin-right: 15px;
        transition: margin .5s;
        height: 25px;
        width: 25px;
        text-align: center;
        display: inline-block;
      }
      &:hover {
        background-color: #999;
        color: rgb(0, 0, 0);
        & .icon {
          margin-right: 25px;
        }
      }
    }
  }
}
.sideOpen {
  .side-bar {
    width: $side-bar-open-width;
    max-width: $side-bar-open-width;
    min-width: $side-bar-open-width;
    display: flex;
  }
}

.isScrolled .side-bar .link-list {
  padding-top: 45px;
}

.send-email {
  text-decoration: none;
  width: calc(#{$side-bar-open-width} - 50px);
  margin: 10px 5% 13px 5%;
  padding: 10px 0 10px 20px;
  background-color: rgb(24, 173, 99);
  color: #fff;
  display: flex;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color .3s;
  & svg{
    margin-right: 20px;
  }
  &:hover {
    background-color: #444;
  }
}

.isMobile .side-bar {
  display: flex;
  position: fixed;
  box-shadow: 0 0 10px rgba(0, 0, 0, .7);
  .send-email {
    margin-bottom: 26px;
  }
}

.isMobile .sideOpen  .side-bar-back {
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, .6);
}

</style>
