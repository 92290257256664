<template>
  <ul>
    <li 
      v-for="(li, index) in listItems"
      :key="index"
      :style="{
        'background-color': index % 2 === 0
          ? accentColor ? accentColor : 'rgb(223, 206, 183)' : ''
      }"
    >
      <span v-for="(span, spanIndex) in li" :key="index + '-' + spanIndex">
        {{ span }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'softlist',
  props: ['listItems', 'accentColor']
}
</script>

<style lang='scss' scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    padding: 12px 10px;
    border-radius: 5px;
    span {
      width: 50%;
      text-align: right;
      &:nth-child(1) {
        color: black;
        text-align: left;
        font-weight: bold;
      }
    }
  }
}
</style>
