<template>
  <div>
    <ul class='groups' v-if="getGroupedKeys.length > 1">
      <li
        v-for="(cat, ind) in [...getGroupedKeys].filter(f => f !== 'no-category-images')"
        :key="ind"
        @click="() => goToSection(cat)"
      >
        {{ cat }}
      </li>
    </ul>
    <div
      v-for="(cat, ind) in getGroupedKeys"
      :key="ind"
      :class="`gallery-${cat.replace(/ /g, '')}`"
    >
      <p
        v-if="cat !== 'no-category-images'"
        class='group-label'
      > {{ cat }} </p>
      <div
        class="my-components-gallery"
        :style="{ 'margin-bottom': config && config.margin ? config.margin + 'px' : 0 }"
      >
        <div
          v-for="(image, index) in groupedImages[cat]"
          :key="index"
          :class="getClasses(index)"
          :style="{ ...getStyles(image, index) }"
          @click="() => mobileClick(image)"
        >
          <span class='image-cover' v-if="!hideCover">
            <span
              class='image-title'
              v-if="image.title"
            >
              {{ image.title }}
            </span>
            <button
              v-if="handleClick"
              @click="() => onClickHandler(image)"
            >
              View image
            </button>
          </span>
        </div>
        <span class='clear-it' />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['images', 'config', 'handleClick', 'hideCover', 'mobileView'],
  name: 'gallery',
  computed: {
    groups: function () {
      const groups = [...this.images].reduce((p, c) => {
        if (c.category) {
          return [...p, c.category]
        } else return p
      }, ['no-category-images'])
      return [...new Set(groups)]
    },
    groupedImages: function () {
      return [...this.images].reduce((p, c) => {
        const useCat = c.category || 'no-category-images'
        if (p[useCat]) {
          return { ...p, [useCat]: [...p[useCat], c] }
        } else {
          return { ...p, [useCat]: [c] }
        }
      }, {})
    },
    getGroupedKeys: function () {
      return [
        'no-category-images',
        ...Object.keys(this.groupedImages).filter(i => i !== 'no-category-images')
      ]
    }
  },
  methods: {
    mobileClick: function (image) {
      this.mobileView && this.handleClick && this.handleClick(image)
    },
    onClickHandler: function (image) {
      this.handleClick && this.handleClick(image)
    },
    goToSection: function (section) {
      const itemClass = `gallery-${section.replace(/ /g, '')}`
      const item = document.getElementsByClassName(itemClass)
      if (item && item.length > 0) {
        const rect = item[0].getBoundingClientRect()
        window.scrollTo({
          top: rect.top - 100,
          left: 0,
          behavior: 'smooth'
        })
      }
    },
    getStyles: function (image, index) {
      const baseHeight = 240
      const config = this.config || {}
      const margin = config.margin || 0
      const radius = config.radius || 0
      const itemClass = this.getClasses(index)
      const styles = {
        width: margin ? `calc(100% / 3 - ${margin * 2}px)` : 'calc(100% / 3)'
      }

      if (itemClass.long) styles.width = margin ? `calc(100% / 3 * 2 - ${margin * 2}px)` : 'calc(100% / 3 * 2)'
      if (itemClass.double) styles.width = margin ? `calc(50% - ${margin * 2}px)` : 'calc(50%)'
      if (itemClass.tall) styles.height = margin ? `calc(${baseHeight}px * 2 + ${margin}px)` : `calc(${baseHeight}px * 2)`

      if (image.thumb || image.image) styles['background-image'] = `url(${image.thumb || image.image})`
      if (radius) styles['border-radius'] = radius + 'px'
      if (margin) {
        styles['margin-right'] = margin + 'px'
        styles['margin-left'] = margin + 'px'
        styles['margin-top'] = margin + 'px'
      }
      return styles
    },
    getClasses: function (itemIndex) {
      const index = itemIndex > 17 ? itemIndex % 18 : itemIndex
      return {
        long: index % 18 === 0 || index === 10,
        short: index === 1,
        tall: index % 18 === 1 || index === 9,
        left: index === 9,
        double:
          index % 18 === 4 || index % 18 === 5 || index === 13 || index === 14,
        three: index % 18 === 6 || index % 18 === 7 || index % 18 === 8
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$base-height: 240px;
$break-point: 700px;

.my-components-gallery {
  width: 100%;
  & > div {
    float: left;
    height: $base-height;
    width: calc(100% / 3);
    background-size: cover;
    background-position: 50% 50%;
    transition: background-position .8s;
    position: relative;
    overflow: hidden;
    background-color: rgb(226, 226, 226);
    &.long {
      width: calc(100% / 3 * 2);
    }
    &.tall {
      width: calc(100% / 3);
      float: right;
      height: calc(#{$base-height} * 2);
      &.left {
        float: left;
      }
    }
    &.three {
      width: calc(100% / 3);
    }
    &.double {
      width: 50%;
    }
    & .image-cover{
      bottom: -300px;
      position: relative;
      background-color: rgba(0, 0, 0, 0);
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: background-color 1s, bottom .5s;
      button {
        cursor: pointer;
        padding: 5px 12px;
        border: 2px solid #fff;
        color: #fff;
        background: transparent;
        font-weight: bold;
        &:hover{ 
          color: #000;
          background-color: #fff;
        }
      }
      & span {
        color: #fff;
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: $break-point) {
      &,
      &.long,
      &.tall,
      &.three,
      &.double {
        width: 96% !important;
        height: $base-height !important;
        margin: 10px auto 10px auto !important;
        float: none !important; 
      }
    }
    &:hover {
      background-position: 45% 40%;
      & .image-cover{
        bottom: 0;
        background-color: rgba(0, 0, 0, .8);
      }
    }
  }
  & .clear-it {
    height: 0;
    clear: both;
    display: block;
  }
}
.group-label {
  font-size: 1.6rem;
  width: 98%;
  margin: 35px auto 3px auto;
  font-weight: bold;
  color: rgb(0, 100, 139);
}

.groups {
  display: flex;
  list-style-type: none;
  margin: 15px 0;
  padding: 0;
  justify-content: flex-end;
  & li {
    opacity: .8;
    padding: 5px 16px;
    background-color: #d9d9d9;
    margin-left: 10px;
    border-radius: 10px;
    margin-right: 4px;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}
</style>
