import Vue from 'vue'
import Vuex from 'vuex'

import app, { state as appState } from './modules/app'
import data, { state as dataState } from './modules/data'

Vue.use(Vuex)

// Timestamp and when to update from server
const readState = localStorage.getItem('readState')
const renewState = readState ? Date.now() > parseInt(readState) : true

const minutesToUpdate = 10
if (renewState) {
  localStorage.setItem('readState', Date.now() + (minutesToUpdate * 60000) + '')
}

const stateWatcher = (store) => {
  store.subscribe((mutation, state) => {
    localStorage.setItem('applicationState', JSON.stringify(state))
  })
}

// If forceLocalState, always use localstorage state
const getInitialState = (stateItem, stateData, forceLocalState) => {
  if (!forceLocalState && renewState) return stateData
  const applicationState = localStorage.getItem('applicationState')
  
  if (applicationState) {
    const jsonState = JSON.parse(applicationState)
    if (jsonState && jsonState[stateItem]){
      const targetState = jsonState[stateItem]
      return {...stateData, ...targetState}
    }
  }
  return stateData
}

const vueStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    data: {
      ...data, 
      state: getInitialState('data', dataState, false)
    },
    app: {
      ...app, 
      state: getInitialState('app', appState, false)
    }
  },
  plugins: [stateWatcher]
})

export default vueStore