<template>
  <div
    class="header"
    :class="{ moveToggle: slide }"
    :style="{
      'background-image': splashUrl ? `url(${splashUrl})` : ''
    }"
  >
    <div class="top-info">
      <div class="head">
        <side-bar-toggle />
        <span class="text">A1 Signs</span>
      </div>
      <div class="call">
        <font-awesome-icon icon="phone" />
        <span>Give us a call today | </span> {{ $store.getters.contact.phone }}
      </div>
    </div>

    <nav :style="{ opacity: calcOpac }">
      <link-list :links="linksLeft" :hideIcons="true" />
      <link-list :links="linksRight" :hideIcons="true" />
    </nav>

    <div class="header-content">
      <span
        class="logo-container"
        :style="{ 'background-image': `url(${logo})` }"
      />
      <div
        :class="{ mounted: isMounted }"
        :style="{ opacity: calcOpac }"
        v-if="isMobile || windowHeight > 800"
        class="text-block"
      >
        <p class="small" v-if="windowHeight > 600">
          Wraps, printing, signs and graphics.
        </p>
        <p class="head">Ready to start your next project?</p>
        <p class="small" v-if="windowHeight > 600">We are.</p>
      </div>
      <div
        :class="{ mounted: isMounted }"
        :style="{ opacity: calcOpac }"
        v-if="!isMobile && windowHeight < 800"
        class="text-block"
      >
        <br />
        <p class="head">Ready to start your next project?</p>
        <p
          class="small center t-c c-a"
          v-if="windowHeight > 600"
          :style="{ textAlign: 'center' }"
        >
          We are.
        </p>
      </div>
      <link-list
        :links="[...links].filter(l => l.text === 'Contact us')"
        :hideIcons="true"
        class="btn"
        :style="{ opacity: calcOpac }"
      />
    </div>
  </div>
</template>

<script>
import logo from "../assets/logo.png";
import SideBarToggle from "./SideBarToggle.vue";
import links from "../router/links";
import LinkList from "./LinkList.vue";
import { baseUrl } from "../services/makeCall";
const mediaUrl = baseUrl + "/open/media/a1Api/images/view/";
export default {
  components: { SideBarToggle, LinkList },
  name: "header-component",
  data: () => ({
    logo,
    links,
    slide: true,
    isMounted: false,
    splashIndex: 0,
    slideTick: 0,
    splashUrl: null
  }),
  mounted: function() {
    const splashUrl =
      mediaUrl +
      "2021-03-21_19-54-17-518612_5896fb65aba645e7842b3fd162310dd1.jpg";
    const image = new Image();
    image.onload = () => {
      this.splashUrl = splashUrl;
      setTimeout(() => {
        this.isMounted = true;
        this.slide = false;
      }, 0);
      window.setTimeout(this.toggleSlide, 20000);
    };
    image.src = splashUrl;
  },
  computed: {
    linksLeft() {
      return [...this.links].splice(0, this.links.length / 2);
    },
    linksRight() {
      return [...this.links]
        .splice(this.links.length / 2, this.links.length)
        .filter(l => l.to !== "map-container");
    },
    calcOpac() {
      return 1 - (this.$store.getters.scrollPos / 1000) * 2;
    },
    windowHeight() {
      return this.$store.getters.windowHeight;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    }
  },
  methods: {
    toggleSlide() {
      this.slide = !this.slide;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/vars.scss";

.header {
  position: fixed;
  top: 0;
  height: $header-height;
  width: 100%;
  z-index: 2;
  background-color: rgb(71, 71, 88);
  transition: height 0.3s, background-position 40s;
  background-size: auto 180vh;
  background-position-y: 43%;
  background-position-x: 43%;
  &.moveToggle {
    background-position-y: 0%;
    background-position-x: 0%;
  }
  & nav {
    position: absolute;
    top: 120px;
    width: 94%;
    color: #fff;
    margin: 0 3%;
    display: flex;
    justify-content: space-between;
    span {
      display: flex;
      a {
        text-decoration: none;
        color: #fff;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        text-shadow: 1px 1px 14px black;
        transition: margin 0.3s, padding 0.3s;
        &:hover {
          background-color: #d9d9d9;
          color: #333;
          text-shadow: none;
          padding-bottom: 16px;
          margin-top: -6px;
        }
      }
    }
    @media screen and (max-width: $large-break) {
      top: 120px;
      flex-wrap: wrap;
      & > span {
        width: 100%;
        margin-bottom: 12px;
        span {
          width: calc(100% / 3);
        }
        a {
          padding: 15px 0;
          width: 100%;
        }
      }
    }
    @media screen and (max-width: $small-break) {
      display: none;
    }
  }
  & .top-info {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    transition: background-color 0.3s;
    justify-content: space-between;
    width: calc(100% - 40px);
    margin: 0;
    padding: 20px;
    align-items: center;
    & .head {
      text-shadow: 0 0 3px black;
      font-size: 2rem;
      display: flex;
      span.text {
        margin-left: 10px;
      }
    }
    & .call {
      background-color: transparent;
      border: 2px solid #fff;
      color: #fff;
      padding: 10px 14px;
      margin-top: -6px;
      margin-right: -5px;
      box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.3);
      border-radius: 15px;
      font-weight: bold;
      & svg {
        padding-right: 6px;
      }
      span {
        padding-left: 6px;
        @media screen and (max-width: $ultra-small-break) {
          display: none;
        }
      }
    }
  }
  & .header-content {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .logo-container {
      background-color: #000;
      height: 100px;
      width: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      padding: 3px;
      border: 5px solid#000;
      border-top: 10px solid #000;
      background-position: 0;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
      position: relative;
      margin-bottom: 25px;
      &::after,
      &::before {
        border-style: solid;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        bottom: -24px;
        z-index: 2;
        border-color: #000 transparent transparent transparent;
      }
      &::before {
        right: -5px;
        border-width: 20px 0 0 59px;
      }
      &::after {
        left: -5px;
        border-width: 20px 59px 0 0;
      }
      @media screen and (max-width: $large-break) {
        opacity: 0;
      }
    }
    .text-block {
      border: 1px solid rgba(218, 218, 218, 0.8);
      box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.6);
      background-color: rgba(0, 0, 0, 0.6);
      text-align: center;
      padding: 10px 30px 13px 30px;
      margin-top: 40px;
      opacity: 0;
      margin-bottom: 30px;
      transition: opacity 0.3s;
      max-width: calc(90% - 60px);
      p {
        font-size: 3.2rem;
        font-weight: bold;
        color: #fff;
        letter-spacing: 0.1rem;
        text-shadow: 2px 2px 10px black;
        margin: 0;
        text-align: right;
        opacity: 0;
        transition: opacity 0.2s;
        &.small {
          margin: 0;
          margin-bottom: 35px;
          margin-top: 45px;
          font-size: 1.5rem;
          color: rgb(170, 170, 170);
        }
        &:first-child {
          text-align: left;
          padding-bottom: 10px;
          margin-left: -100px;
          transition: margin 0.2s;
        }
        transition-delay: 0.3s;
      }

      &.mounted {
        opacity: 1;
        p {
          opacity: 1;
          margin-left: 0;
        }
      }
      @media screen and (max-width: $large-break) {
        p {
          text-align: center;
          margin: 0;
          &.head {
            font-size: 3rem;
            line-height: 4rem;
          }
          &.small {
            text-align: center;
          }
          &:first-child {
            margin-bottom: 20px;
            text-align: center;
          }
        }
      }
      @media screen and (max-width: $small-break) {
        p {
          text-align: center;
          &.head {
            font-size: 2.5rem;
          }
        }
      }
    }

    .btn {
      background-color: #07da00;
      color: #000;
      font-weight: bold;
      font-size: 1.2rem;
      padding: 15px 33px;
      border: none;
      border-radius: 5px;
      margin-bottom: 74px;
      &:hover {
        background-color: gray;
        color: #d9d9d9;
      }
    }
  }
}
</style>
