<template>
  <div class='services-container'>
    <div class='page-header-accent'>
      <div>
        <h3 class='head'>What we can do for you</h3>
        <p class='page-descr'>
          A1 Signs &amp; Graphics offers a large array of quality products and services. Click a product or service from the list below for more information. <br />
          Our products can be designed as simple or as complex as you would like. Our goal is to design a creative and effective design for you.
        </p>
      </div>
    </div>
    <div class='container'>
      <div class='cols pb-10 pt-10 services-cols'>
        <div class='col-50'>
          <span
            class='section-title'
            :style="{'background-image': `url(${wrapSplash})`}"
          >
            Installation
          </span>
          <p>
            <span>
              Installation provided for signs, banners, wraps and all other products printed by A1 Signs &amp; Graphics.
            </span>
          </p>
        </div>
        <div class='col-50'>
          <span
            class='section-title'
            :style="{'background-image': `url(${wrapSplash})`}"
          >
            Design Services
          </span>
          <p>
            <span>
              A1 Signs &amp; Graphics provides professional quality design work for all printed products listed here.
            </span>
          </p>
        </div>
      </div>
      <span v-if="Object.keys(tContent).length > 0">
        <h4 class='section-title'>Product catalog</h4>
        <p>
          While we never turn down the chances to work on something new. Here are just a few products that we specialize in. <br />
          If you have other products or ideas not listed here, don't hesitate to 
          <link-list 
            :links="Links.filter(l => l.text === 'Contact us')"
            class='body-link'
            :hideIcons="true"
          />
        </p>
        <tabbed-content :content="tContent" class='mt-20'/>
      </span>
    </div>
  </div>
</template>

<script>
import TabbedContent from '../../components/myComponents/TabbedContent'
import LinkList from '../../components/LinkList.vue'
import wrapSplash from '../../assets/wrap-splash.jpg'
import Links from '../links'

export default {
  components: { TabbedContent, LinkList },
  name: 'Services',
  data: () => ({ wrapSplash, Links }),
  mounted: function () {
    if (!this.$store.getters.servicesOffered.loaded) {
      this.$store.dispatch('loadedServicesOffered')
    }
  },
  computed: {
    tContent () {
      return this.$store.getters.servicesOffered.data
    }
  }
}
</script>

<style scoped lang='scss'>
@import '@/styles/vars.scss';
.services-container {
  margin: 0 auto;
  .section-title {
    font-weight: bold;
    font-size: 1.1rem;
  }
  h4.section-title {
    font-size: 1.4rem;
    margin-bottom: 20px;
    color: $accent-1;
  }
  .services-cols {
    text-align: center;
    margin-bottom: 14px;
    & .section-title {
      background-color: rgb(92, 92, 92);
      display: flex;
      width: 120px;
      height: 120px;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      color: #fff;
      border: 8px solid rgb(255, 255, 255);
      box-shadow: 0 0 5px gray;
      border-radius: 50%;
      z-index: 2;
      position: relative;
      line-height: 1.7rem;
    }
    p {
      width: calc(96% - 20px);
      margin: 0 auto;
      padding: 10px;
      box-shadow: inset 0 0 0 8px rgb(248, 248, 248), 0 0 5px rgb(165, 165, 165);
      padding-bottom: 28px;
      padding-top: 75px;
      margin-bottom: 40px;
      margin-top: -60px;
      z-index: 1;
      span {
        display: block;
        width: 95%;
        margin: 0 auto;
      }
    }
    @media screen and (max-width: $med-break) {
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
  }
}
</style>
