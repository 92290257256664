<template>
  <span
    @click="toggleSideBar" class='side-bar-toggle'
    v-if="$store.getters.isMobile"
    :style="{
      height: size || '35px',
      width: size || '35px',
      'margin-right': marginRight + 'px' || 0
    }"
  >
    <span>
      <font-awesome-icon :icon="sideOpen ? 'times' : 'bars'" />
    </span>
  </span>
</template>

<script>
export default {
  props: ['size', 'marginRight'],
  methods: {
    toggleSideBar () {
      this.$store.commit('updateSideOpen')
    }
  },
  computed: {
    sideOpen () {
      return this.$store.getters.sideOpen
    }
  }
}
</script>

<style scoped>
  .side-bar-toggle {
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    padding: 0;
  }
  .side-bar-toggle span{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .side-bar-toggle:hover {
    background-color: rgba(0, 0, 0, .2);
  }
</style>
