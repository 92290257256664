<template>
    <div class='my-modal' v-if="isMounted" :class="{'modal-mounted': classToggle}">
        <div class='modal-content'>
            <div class='modal-header'>
                <span class='title'>{{ image.title }}</span>
                <span>
                    <button @click="onCloseClick">X</button>
                </span>
            </div>
            <div 
                class='modal-body'
                :style="{
                    'background-image': imageToUse ?
                        `url(${imageToUse})` : ''
                }"
            />
            <div class='modal-footer'>
                <button @click="okayClickHandler">
                    Okay
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['isMounted', 'image', 'onCloseClick', 'okayClick'],
    name: 'my-modal-component',
    data: () => ({ 
        classToggle: false,
        useImage: null
    }),
    watch: {
        isMounted (value) { 
            if (value === false) { 
                this.classToggle = false
                this.useImage = false
            }
            if ( value ) {
                setTimeout(() => { this.classToggle = true }, 1)
                let img = new Image();
                img.onload = () => {
                    this.useImage = this.image.image
                }
                img.src = this.image.image;
            }
        }
    },
    methods: {
        okayClickHandler () {
            this.okayClick && this.okayClick()
            this.onCloseClick()
        }
    },
    computed: {
        imageToUse () {
            return this.useImage || (this.image && this.image.thumb)
        }
    }
}
</script>

<style lang="scss" scoped>
$ends-heights: 60px;
.my-modal {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transition: opacity .1s;
    background-color: rgba(0, 0, 0, .8);
    z-index: 15;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    .modal-content {
        width: 98%;
        transition: margin .1s;
        height: 100vh;
        .modal-header, .modal-footer {
            display: flex;
            justify-content: space-between;
            padding: 0 12px;
            height: $ends-heights;
            align-items: center;
        }
        .modal-header {
            padding-bottom: 8px;
            padding-top: 8px;
            .title {
                font-size: 1.5rem;
                font-weight: bold;
                color: #d1d1d1;
            }
            button {
                background-color: red;
                border-radius: 50%;
                padding: 0;
                height: 28px;
                width: 28px;
            }
        }
        .modal-body {
            height: calc(100% - 20px - #{$ends-heights} * 2);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .modal-footer {
            justify-content: flex-end;
            button {
                padding: 8px 20px;
                background-color: green;
                border-radius: 4px;
            }
        }
        button {
            border: none;
            cursor: pointer;
            color: #fff;
            font-weight: bold;
            &:hover {
                background-color: gray;
            }
        }
    }

    &.modal-mounted {
        opacity: 1;
    }
}
</style>