<template>
    <div class='footer'>
        <div class='row split'>
            <div class='footer-links'>
                <h4>Find us on: </h4>
                <a href='https://www.facebook.com/A1SignsandGraphics/' target="_blank">
                    <img :src="fbLogo" class='icon' /> Facebook
                </a>
                <a class='google' href='https://www.google.com/search?sxsrf=ALeKk03gYZQEiJYh_lGmQ0fCWuW3hVDhPQ%3A1608146831007&ei=j1_aX6kG5OX9BrPJoYgG&q=a1+signs+and+graphics+humble+texas&oq=a1+signs+and+graphics+&gs_lcp=CgZwc3ktYWIQARgAMgQIIxAnMgsILhDHARCvARDJAzIICC4QxwEQrwEyCAguEMcBEK8BMgYIABAWEB4yBggAEBYQHjICCCY6BwgjELADECc6DQguEMcBEK8BELADEEM6BwgAEEcQsANQ7zhY7zhgjD5oAXACeACAAf8BiAH_AZIBAzItMZgBAKABAaoBB2d3cy13aXrIAQjAAQE&sclient=psy-ab' target="_blank">
                    <img :src="gLogo" class='icon' /> Google
                </a>
            </div>
            <div>
                <p>
                    All rights reserved &copy; A1 Signs <br />
                    <span class='release-version'>{{ releaseVersion }}</span> 
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import fbLogo from '../assets/fb-logo.png'
import gLogo from '../assets/google-logo.png'
import releaseVersion from '../releaseVersion'
export default {
    name: 'footer-content',
    data: () => ({ fbLogo, gLogo, releaseVersion })
}
</script>

<style lang='scss'>
@import '../styles/vars.scss';
.footer {
  min-height: 200px;
  margin-top: 50px;
  background-color: #444;
  font-weight: bold;
  color: #bdbdbd;
  & > div {
    margin: 0 auto;
    padding-top: 28px;
    p {
        margin: 0;
        padding: 0;
        text-align: right;
        line-height: 1.3rem;
        .release-version {
            opacity: .3;
            font-size: .5rem;
        }
    }
    .footer-links {
        h4 {
            font-family: $font-stack-2;
            margin: 0 0 20px 0;
            font-size: 1.1rem;
        }
        a {
            margin-left: 10px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            color: #fff;
            text-decoration: none;
            opacity: .5;
            transition: opacity .2s;
            &:hover {
                opacity: 1;
            }
            .icon {
                width: 26px;
                height: 26px;
                margin-right: 10px;
            }
        }
    }
  }
}
</style>