import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Styles
import 'normalize.css'
import './styles/base.scss'

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { 
  faPhone, faBars, faTimes, faHandPointRight, faEnvelope, faHSquare, faBook, faConciergeBell, faImages, faIdCard, faCalculator, faMap
} from '@fortawesome/free-solid-svg-icons'

library.add(faPhone, faBars, faTimes, faHandPointRight, faEnvelope, faHSquare, faBook, faConciergeBell, faImages, faIdCard, faCalculator, faMap)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
