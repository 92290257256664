export const baseUrl = "https://06designs.com";
const apiURl = "a1-api/site/resources";

// 'open/email/<domain>/'
const makeCall = async data => {
  const { url, endPoint, method, body } = data;
  const useEndpoint = endPoint || apiURl;

  let callData = {
    method: method || "GET",
    headers: { "Content-type": "application/json" }
  };

  if (body) {
    callData.body = JSON.stringify(body);
  }

  if (url) {
    const useUrl = `${baseUrl}/${useEndpoint}/${url}/`;
    const resp = await fetch(useUrl, callData);
    const json = await resp.json();
    return json.data || json;
  }
};

export default makeCall;
