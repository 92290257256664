<template>
  <div class="my-components-form">
    <p 
      class='form-message'
      v-if="formMessage && formMessage !== ''"
      :class="{'form-error': formError}">
      {{formMessage}}
    </p>
    <div
      v-for="(fGroup, groupIndex) in Object.keys(groupedFields)"
      :key="'group-' + groupIndex"
      class="field-group-container"
    >
      <h4 
        class='field-group-title'
        v-if="fGroup !== 'no-group-data'"
      >{{ fGroup }}</h4>
      <div
        v-for="(f, index) in groupedFields[fGroup]"
        :key="index"
        class='field-container'
        :class="{ long: f.size && f.size === 'long' }"
      >
        <label v-if="f.label || forceLabel">
          {{ f.label || f.name.charAt(0).toUpperCase() + f.name.split('').splice(1, f.name.length).join('')}}
        </label>
        <input
          :type="f.type || 'text'"
          :name="f.name"
          v-if="!f.type || ['text', 'number'].includes(f.type)"
          :value="formData[f.name]"
          @input="fieldChange"
          :class="{hasError: errors[f.name]}"
        />
        <textarea
          :name="f.name"
          :value="formData[f.name]"
          v-if="f.type && f.type === 'textArea'"
          :class="{hasError: errors[f.name]}"
          @input="fieldChange"
        />
        <p class="field-error" v-if="errors[f.name]">{{ errors[f.name] }}</p>
      </div>
    </div>
    <div class='field-group-container' v-if="hasSecurity">
      <h4 
        class='field-group-title'
      >Security</h4>
      <div class='field-container long'>
        <label>
          What day comes after {{ displayedSecurityAnswer }} ?
        </label>
        <select @change="securityChangeHandler">
          <option
            value=''
          >
            Choose answer
          </option>
          <option
            v-for="(option, index) in security.options"
            :key="option"
            :value="index"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
    <div class="btn-group">
      <button
        class="btn"
        @click="handleSubmit"
        :disabled="formLoading || (hasSecurity && security.answer !== security.input)"
      >
        {{ formLoading ? 'Loading' : 'Submit' }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["fields", "retainInputsOnUpdate", "onSubmit", "hasSecurity", "forceLabel"],
  name: "myform",
  data: () => ({
    formData: {},
    errors: {},
    security: {
      options: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      answer: 0,
      input: null
    },
    formMessage: null,
    formError: false,
    formLoading: false,
    groupedFields: {},
  }),
  mounted: function () {
    this.security = {
      ...this.security,
      answer: Math.floor(Math.random() * Math.floor(this.security.options.length - 1))
    }
    this.processFieldData(true, false)
  },
  watch: {
    fields () {
      this.formMessage = null
      this.formError = false
      this.errors = {}
      this.processFieldData(false, this.retainInputsOnUpdate)
    }
  },
  computed: {
    displayedSecurityAnswer () {
      let answer = this.security.answer - 1
      return answer === -1
        ? this.security.options[this.security.options.length - 1]
          : this.security.options[answer]
    }
  },
  methods: {
    securityChangeHandler: function (e) {
      this.security = {
        ...this.security,
        input: parseInt(e.target.value)
      }
    },
    fieldChange: function (e) {
      this.formData = {
        ...this.formData,
        [e.target.name]: e.target.value,
      };
    },
    handleSubmit: async function (e) {
      let errors = {}
      this.fields.map(f => {
        if(f.validate) {
          const fVal = this.formData[f.name] && this.formData[f.name].replace(/ /g , '')
          // Required validator
          if(f.validate.required){
            if ( !this.formData[f.name] || fVal === '' ) {
              errors[f.name] = 'Field is required.'
            }
          }
          // Type validators
          if (fVal && f.validate.type) {
            if (f.validate.type === 'email'){
              if(fVal.length < 3 || !fVal.includes('@') || !fVal.includes('.')) {
                errors[f.name] = 'Invalid email address'
              }
            }
            if (f.validate.type === 'phone'){
              if(fVal.length < 6) {
                errors[f.name] = 'Invalid phone number'
              }
            }
          }
          // customer validators
          if (f.validate.use) {
            const error = f.validate.use(this.formData)
            if (error) errors[f.name] = error
          }
        }
      })

      this.errors = errors

      if(Object.keys(errors).length > 0) {
        this.formError = true
        this.formMessage = 'Please correct errors'
      } else {
        if (this.onSubmit) {
          this.formError = false
          this.formMessage = null
          this.formLoading = true
          const response = await this.onSubmit(this.formData)
          if (response) {
            response.clear && this.processFieldData(true, false)
            if (response.message) this.formMessage = response.message
            this.formError = response.error || false
          }
          this.formLoading = false
        }
      }


    },
    processFieldData: function (initial = false, retainChanges = true) {
      let formData = {};
      let groupedFields = this.fields.reduce((p, c) => {
        if (c.default) formData = { ...formData, [c.name]: c.default };
        const useGroup = c.group || "no-group-data";
        if (p[useGroup]) {
          p[useGroup] = [...p[useGroup], c];
        } else {
          p[useGroup] = [c];
        }
        return p;
      }, {});
      if(initial || !retainChanges) this.formData = formData;
      this.groupedFields = groupedFields;
    }
  },
};
</script>

<style lang="scss" scoped>
.my-components-form {
  padding: 2%;
  .field-group-container {
    display: flex;
    flex-wrap: wrap;
    & .field-group-title {
      width: 100%;
      font-size: 1.25rem;
      margin: 0;
      margin-top: 23px;
    }
    & .field-container {
      $field-group-margin: 10px;
      $field-padding-end: 15px;
      margin-left: $field-group-margin;
      margin-right: $field-group-margin;
      width: calc(50% - #{$field-group-margin * 2});
      &.long {
        width: calc(100% - #{$field-group-margin * 2});
      }
      label {
        color: rgb(114, 114, 114);
        display: block;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 9px;
        font-size: 1.1rem;
      }
      .field-error {
        color: red;
        padding: 0;
        margin: 6px 0 0 6px;
      }
      input[type="text"],
      input[type="number"],
      select,
      textarea {
        padding: 15px $field-padding-end;
        border: none;
        background-color: rgb(237, 247, 250);
        box-shadow: 0 0 0 1px rgb(201, 223, 255);
        width: calc(100% - #{$field-padding-end * 2});
        border-radius: 5px;
        transition: background-color 0.3s;
        &:focus {
          background-color: #fff;
        }
        &.hasError {
          background-color: rgb(255, 231, 235);
          box-shadow: 0 0 0 1px red;
        }
      }
      select {
        width: 100%;
      }
      textarea {
        height: 120px;
      }
    }
  }
  & .btn-group {
    width: 98%;
    padding-top: 20px;
    text-align: right;
  }
  button:disabled {
    color: gray;
    border-color: gray;
    cursor: default;
    &:hover {
      background: transparent;
    }
  }
  .form-message {
    font-weight: bold;
    color: green;
    border-radius: 10px;
    text-align: center;
    padding: 6px 0;
    &.form-error {
      color: rgb(243, 13, 13);
    }
  }
}
</style>
